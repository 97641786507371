import React, { useState } from 'react';
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Input } from 'semantic-ui-react';

const EditBox = (props) => {
    const [label, setLabel] = useState(props.label || '');

    return (     
          <Dialog
            open={true}
            onClose={props.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {props.caption}
              </DialogContentText>                
                <Input
                    placeholder="Enter custom label"
                    value={label}
                    onChange={(e) => setLabel(e.target.value)}
                />                
              
            </DialogContent>
            <DialogActions>
              <Button onClick={props.handleClose}>Cancel</Button>
              <Button onClick={() => props.handleOK(label)}>OK</Button>
            </DialogActions>
          </Dialog>
      )
  }

  export default EditBox;