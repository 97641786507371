import React, { Component } from 'react'
import firebase from '../../firebase';
import {Checkbox, TextArea, Form, Input} from 'semantic-ui-react';
import withUID from './../withUID';
import ApplyButton from './../UI/ApplyButton';
import Slider from '../UI/Slider';
import {minutesToString} from '../../utils';
import { applySettings } from './../../Firebase/applySettings';
import ToolTip from '../UI/ToolTip';

class GeneralSettings extends Component {
    state = {
        settingsRef : null,              
        settings : {
            applications : true,
            websites : true, 
            computerTime : true,
            queries : true,
            showStartMessage : false,
            startMessage : "System administration software is currently working on this computer.",
            showFilterWarning : true, 
            redirectTo : '',
            idleTimeout : 5,
            showTimer : true,
        }, 
        data : null     
    }

    checkboxes = [
        { name : 'applications', label : 'Record applications'},
        { name : 'websites', label : 'Record websites'},        
        { name : 'computerTime', label : 'Record computer time'},
        { name : 'queries', label : 'Record search queries'},        
    ]

    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                
        this.setState({settingsRef});
        settingsRef.child('generalSettings').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }  
    
    componentWillUnmount() {
        if (this.state.data) {
            this.handleSaveSettings();
        }
    }

    handleSaveSettings = () => {        
        this.state.settingsRef.child("generalSettings").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		        
    }

    onChange = (name, value) => {            
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        		

    }
    
    render() {	
        const {version} = this.props;
        const {showStartMessage,startMessage, showTimer, showFilterWarning, redirectTo , idleTimeout = 5} = this.state.settings;
        const monitoringSettings = this.checkboxes.map((checkbox) => {
         
            return(
                <div key={checkbox.name} style = {{padding : 10}}>
                    <Checkbox onChange={(e, data) => this.onChange(checkbox.name, data.checked)} checked={this.state.settings[checkbox.name]} label={checkbox.label}/>		
                </div>
            )
        });

        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/> 
				<h2>General Settings</h2>	                                
                <h3>Monitoring Settings <ToolTip text="Configure what activities are monitored by HT Family Shield."/></h3>
                {monitoringSettings}
                <h3>Startup Settings</h3>				
                <div style = {{padding : 10, maxWidth: "550px"}}>
				    <Checkbox label="Show warning when the program starts" checked={showStartMessage} onChange={(e, data) => this.onChange("showStartMessage", data.checked)}/><ToolTip text="Enable this option to display a custom warning message each time HT Family Shield is launched. You can personalize the warning message below."/>				    
                    <div style={{marginTop: "10px"}}>
                        <Form>
                            <TextArea placeholder="Startup message" value={startMessage || ''} onChange={(e, data) => this.onChange("startMessage", data.value)} />
                        </Form>
                    </div>
                </div>
                
                <h3>Advanced Settings</h3>				
                <div style = {{padding : 10}}>
                    <Checkbox label="Show message when blocking" checked={showFilterWarning} onChange={(e, data) => this.onChange("showFilterWarning", data.checked)}/>
                </div>
                {version >= 9601&&<div style = {{padding : 10}}>                    
                    <Checkbox label="Show remaining time window" checked={showTimer} onChange={(e, data) => this.onChange("showTimer", data.checked)}/>                    
                </div>}                
                <div style ={{padding : 10, paddingTop: 0, maxWidth : "550px", display : 'flex', flexDirection : 'row', alignItems : "center"}}>
                    <div style={{marginRight: 10}}>Redirect banned websites to</div>
                    <Input style={{flexGrow: 2}} value={redirectTo || ''} placeholder="about:blank" onChange={(e, data) => this.onChange("redirectTo", data.value)}/>                
                </div>
                <div style ={{padding : 10, paddingTop: 0, maxWidth : "550px"}}>
                    <div style={{marginRight: 10, maxWidth: '550px'}}>Idle Timeout</div>
                        <Slider value={idleTimeout} min={1} max={20} onChange={(e, value) => this.onChange('idleTimeout', value)}/>
                        <div style={{padding : "10px", paddingTop: "0"}}>
                            {minutesToString(idleTimeout)}                                
                        </div>                 
                </div>                           
            </>
        )
    }
}

export default withUID(GeneralSettings);
