import React, { Component } from 'react';
import firebase from '../../firebase';
import {Dropdown, List, Image, Icon, Message} from 'semantic-ui-react';
import Schedule from '../UI/Schedule';
import Slider from '../UI/Slider';
import {minutesToString} from '../../utils';
import {modeOptions, modeOptions2, appCategories, appCategories_2022, statusOptions, appCategories_2023} from '../consts';
import withUID from './../withUID';
import ApplicationList from './../UI/ApplicationList';
import moment from 'moment';
import ApplyButton from './../UI/ApplyButton';
import { applySettings } from './../../Firebase/applySettings';
import DisableWrapper from '../UI/Disable';
import ToolTip from '../UI/ToolTip';


class ApplicationFilter extends Component {
    state = {
        settingsRef : null,               
        settings : {},          
        data : null, // updated settings
    }

    getInstalledApplications = (uid, currentComputer) => { 
        // check cache
        const timestamp = +localStorage.getItem(`timestamp/${uid}/${currentComputer}/applications`);    
        if (moment().unix() < timestamp + 60) {
            const installedApplications = JSON.parse(localStorage.getItem(`${uid}/${currentComputer}/applications`));    
            this.setState({installedApplications});               
        } else {
            const applicationsRef = firebase.database().ref(`${uid}/${currentComputer}/applications`);
            applicationsRef.once('value',
            // success
            (snapshot) => {
                const raw = snapshot.val() || {};
                const installedApplications = Object.keys(raw).filter(key => raw[key]).map((key) => {
                    return(
                        {key, name: raw[key]}
                    )
                });

                installedApplications.push({key:'0eadcb5543bc5b9bb1db091e963b56de', name:'Roblox'});
            
                installedApplications.sort((a, b) => a.name.localeCompare(b.name));

                localStorage.setItem(`${uid}/${currentComputer}/applications`, JSON.stringify(installedApplications));
                localStorage.setItem(`timestamp/${uid}/${currentComputer}/applications`, moment().unix());    
                this.setState({installedApplications});                   
              },
            // error
            (error) => {
                console.log(error);            
            });    
        }
    }       
    
    loadSettings = (uid, currentComputer) => {    
        const settingsRef = firebase.database().ref(`${uid}/${currentComputer}/settings`);                
        this.setState({settingsRef});
        settingsRef.child('applicationFilter').once('value',
        // success
        (snapshot) => {        
            const settings = snapshot.val() || {};       
            this.setState({settings});
          },
        // error
        (error) => {
            console.log(error);            
        });
    }

    componentDidMount() {
        if (this.props.currentComputer) this.getInstalledApplications(this.props.uid, this.props.currentComputer);            
        if (this.props.currentComputer) this.loadSettings(this.props.uid, this.props.currentComputer);    
    }    
    
    componentWillUnmount() {
        if (this.state.data) {        
            this.handleSaveSettings();
        }
    }    

    handleSaveSettings = () => {            
        this.state.settingsRef.child("applicationFilter").update(this.state.data);        
        applySettings(this.props.uid, this.props.currentComputer);
        this.setState({data : null})		
    }

    onChange = (name, value) => {             
        this.setState(prevState => ({
            settings: { ...prevState.settings,  [name] : value },
            data : {...prevState.data, [name] : value}
        }));        
    }

    render() {			
        const maxLimit = 121; // 120 - 10 hours, 121 - Unlimited
        const {blacklist = [], timeLimit = 121, mode = 'day', schedule} = this.state.settings;  
        const minutes = timeLimit * modeOptions2.find(item => item.value === mode).multiplier;     
        const {version} = this.props; 
        // Application Categories
        const categories = version >= 8401 ? appCategories_2023 : version >= 6301 ? appCategories_2022 : appCategories;        
        const appCategoriesList = categories.map((item) => (            
            <List.Item key={item.name}>
                <div className='blacklist-container' style={{padding: "0.4rem"}}>
                    <div className='blacklist-title'>
                        <Image floated='left' style={{opacity: '0.6', width: '1.3em', height: '1.3em'}} src={item.image}/>
                        <div style={{color : 'rgba(0,0,0,.87)'}}>{item.label}</div>                    
                    </div>  
                    <div className='blacklist-status' style={{marginRight: '55px'}}>
                        <Dropdown                                                     
                            value = {this.state.settings[item.name]}
                            size="large"
                            onChange = {(e, {value}) => this.onChange(item.name, value)}
                            options={statusOptions()}
                            defaultValue='allow'
                        />
                    </div>                                                                                      
                </div>              
            </List.Item>                            
        )); 
        

        return (
            <>
                <ApplyButton onClick={this.handleSaveSettings} enabled={this.state.data}/>           
				<h2>App Blocking
                    <a href='https://ht-vector.com/ht-family-shield-how-to-use#block-app' rel="noopener noreferrer" target="_blank"><Icon name='question circle' className="help-icon"/></a>                                        
                </h2>                	                                                                
                <DisableWrapper isDisabled={false}>                
                <div className="flex-box" style={{marginTop: "-15px"}}>                                    
                        {(version >= 3203)&&<div style={{width : '550px', marginTop : "0px", marginRight: "10px"}}> 
                            <h3>App Categories</h3>                    
                            <div style={{marginTop : "0px", marginRight: "10px"}}>                          
                                <List selection size="large">{appCategoriesList}</List>  
                            </div>                           
                            </div> }
                        <div style={{width : '550px', marginTop : "0px", marginRight: "10px"}}>   
                        <h3>Custom List</h3>          
                        <ApplicationList installedApplications={this.state.installedApplications} list={blacklist} name='blacklist' onChange={this.onChange} style = {{padding : 10, marginRight : '15px',  height : '300px', overflowY: 'auto', border : '1px solid #ddd'}}/>                                                             
                        </div>
                    </div>        
                    <div className="flex-box">           
                        <div style={{minWidth : "300px", width : '550px', paddingRight: '25px'}}>
                        <h3>Max Time on Limited Apps / Categories <ToolTip text="Set the maximum usage time for limited apps and categories. This limit is in effect during the scheduled access times defined as allowed."/></h3>                     
                                <Slider value={timeLimit} min={1} max={maxLimit} onChange={(e, value) => this.onChange('timeLimit', value)}/>
                                <div style={{padding : "10px", paddingTop: "0"}}>
                                    {timeLimit < maxLimit ? minutesToString(minutes) : "Unlimited"}
                                    {(timeLimit < maxLimit)&&<Dropdown                             
                                        style={{margin : 0, marginLeft: "10px"}} 
                                        value = {mode}
                                        onChange = {(e, {value}) => this.onChange('mode', value)}
                                        options={version >= 4801 ? modeOptions2 : modeOptions}
                                    />}                     
                                </div>               
                        </div>
                        <div style={{minWidth : "300px"}}>
                        <Schedule 
                            title={<>Access Schedule for Limited Apps / Categories<ToolTip text="Define the hours during which limited apps and categories can be accessed."/></>}
                            schedule={schedule} 
                            onChange={schedule => this.onChange('schedule', schedule)}
                        />   
                        </div>                     
                    </div>                                            
            </DisableWrapper>
            <Message info>
                <div style={{marginTop: 10}}><Icon name="check circle" style={{fontSize : "18px", color : "#28a745", marginRight: 8, float : "left"}}/>Allow: No restrictions applied.  </div>    
                <div style={{marginTop: 10}}> <Icon name="minus circle" style={{fontSize : "18px", color : "#dc3545", marginRight: 8, float : "left"}}/>Block: Access completely denied</div>                
                <div style={{marginTop: 10}}><Icon name="time" style={{fontSize : "18px", color : "#f5c242", marginRight: 8, float : "left"}}/>Limit: Access restricted according to the schedule and time limits</div>                         
                </Message>                
            </>            
        )
    }
}

export default withUID(ApplicationFilter);
 