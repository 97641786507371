import React, { useState, useEffect } from 'react';
import firebase from '../../firebase';
import ReportTable from '../UI/ReportTable';
import { Table, Icon, Dropdown } from 'semantic-ui-react';
import moment from 'moment';
import AppIcon from '../UI/AppIcon';
import WebIcon from '../UI/WebIcon';
import isURL from 'validator/lib/isURL';
import withUID from './../withUID';
import ClearLogsButton from '../UI/ClearLogsButton';
import ToolTip from '../UI/ToolTip';
import withWebFilter from './withWebFilter';
import withAppFilter from './withAppFilter';

const AlertsIcon = ({ name, reason }) => {
  if ((reason === 'locally') || (reason === 'onlne dashboard')) {
    return <div><Icon name="exclamation circle" color="grey" style={{marginLeft:5, marginRight:10}}/>{name}</div>
  }

  if (reason === 'Time Controls') {
    return <div><Icon name="clock outline" color="grey" style={{marginLeft:5, marginRight:10}}/>{name}</div>
  }

  const link = /^(f|ht)tps?:\/\//i.test(name) ? name : 'http://' + name;
  return isURL(name) ? (
    <WebIcon name={name}>
      <a href={link} rel="noopener noreferrer" target="_blank">
        {name}
      </a>
    </WebIcon>
  ) : (
    <AppIcon name={name} />
  );
};

const ApplicationItem = ({item, onAddClick }) => {
  const { name, title, time, reason } = item;
  const hasAppOrWeb = reason.includes('App -') || reason.includes('Web -');

  return (
    <>      
      <Table.Cell><span style={{marginLwft:10}}>{moment.utc(time, 'X').format('D MMM, h:mm a')}</span></Table.Cell>      
      <Table.Cell className="reason">{reason}</Table.Cell>      
      <Table.Cell><AlertsIcon name={name} reason={reason}/></Table.Cell>
      <Table.Cell className="trunc">{title}</Table.Cell>
      <Table.Cell>
          {hasAppOrWeb && (
            <Dropdown className="actions-dropdown" icon="ellipsis vertical" direction="left" text=''>       
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onAddClick(name, 'allow')}>
                        <div><Icon className="allow-icon" name="check circle"/>Add to Allowlist</div>
                    </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>)}
        </Table.Cell>         
      
      
    </>
  );
};

const Alerts = ({ currentComputer, uid, onAddApp, onAddWebsite }) => {
  const [alerts, setAlerts] = useState([]);
  const [reportRef, setReportRef] = useState(null);

  const handleClearLogs = () => {
    if (reportRef) reportRef.remove();
    setAlerts([]);
  };

  const onAddClick = (name, status) => {
    if (isURL(name)) {
      onAddWebsite(name, status);
     } else  {
      onAddApp(name, status);
     }
  }

  useEffect(() => {
    if (!currentComputer) return;
    const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/alerts`);
    setReportRef(ref);

    const getAlerts = (snapshot) => {
      const alerts = Object.values(snapshot.val() || {});
      alerts.sort((a, b) => b.time - a.time);
      setAlerts(alerts);
    };

    ref.on('value', getAlerts, (error) => console.log(error));

    return () => {
      if (reportRef) reportRef.off();
    };
  }, []);

  const columns = [    
    { title: 'Time', style: { width: '160px' } },
    { title: 'Reason', style: { width: '160px' } },    
    { title: '', style: { width: '' } },
    { title: 'Title', style: { width: '' } },
    { title : ' ', className : 'th-fixed-50'},   
    
    
  ];

  return (
    <>
      <ClearLogsButton onClick={handleClearLogs} />
      <h2>Alerts - Blocked Activities
        <ToolTip 
          pinned 
          text={<>Alerts show blocked activities attempted by your child.<br/><a href='https://ht-vector.com/ht-family-shield-how-to-use#alerts-header' rel="noopener noreferrer" target="_blank">[Learn more]</a></>}                                                    
        />
      </h2>
      <div style={{ padding: 10 }}>
        <ReportTable collumns={columns} list={alerts}>
          {(index, item) => <ApplicationItem index={index} item={item} onAddClick={onAddClick}/>}
        </ReportTable>
      </div>
    </>
  );
};

export default withUID(withAppFilter(withWebFilter(Alerts)));