import React, { useState, useEffect } from 'react'
import firebase from '../../firebase';
import {Icon, Message} from 'semantic-ui-react';
import moment from 'moment';
import withUID from './../withUID';
import AppChartTable from './tables/AppChartTable';
import DatePicker_2023 from '../UI/DatePicker_2023';
import _ from 'lodash';
import { setWeek, setDate } from '../../Store/actions';
import { connect } from 'react-redux';

const AppChart = ({uid, currentComputer, date, setDate, week, setWeek}) => {
    const [items, setItems] = useState([]);
    const [isToday, setIsToday] = useState(false);
    const [dates, setDates] = useState([]);
    const [lastUpdate, setLastUpdate] = useState(moment());             

    const startDate = moment(date).startOf('isoWeek');
    const days = !week ? [moment(date).format('YYMMDD')] :  Array.from({ length: 7 }, (_, i) => (moment(startDate).add(i, 'days').format('YYMMDD'))); 

    useEffect(() => {
        if (!currentComputer) return;

        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/applications/dates`);  
        ref.once('value', // success
            (snapshot) => {                                                
                const datesRaw = Object.keys(snapshot.val() || {}); 
                if (datesRaw.length) {
                    const dates = datesRaw.map(date => moment(date, 'YYMMDD').toDate());                                   
                    const latestDate = moment(new Date(Math.max.apply(null, dates)));    
                    const isToday = moment().diff(latestDate, 'days') === 0;                                        
                    setDates(dates);
                    if (moment().diff(date, 'days') === 0) setDate(latestDate);
                    setIsToday(isToday);
                }
            }, // error
            (error) => {
                console.log(error);            
            });                
    }, [uid, currentComputer]);

    useEffect(() => {
        setItems([]);
        if (currentComputer && dates) {
            const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/applications`);  
            days.forEach(date => {        
                ref.child(date).once('value',                
                // success
                (snapshot) => {                
                    const items = Object.values(snapshot.val() || {});    
                    setItems(prevItems => ({ ...prevItems, [date]: items }));                                                                          
                },
                // error
                (error) => {
                    console.log(error);            
                });  
            });                    
        }
    }, [uid, currentComputer, date, week, lastUpdate]);
    
	const handleChangeDate = (date) => {                  
        setDate(date);
    }   
        
    const handlePreviousDay = () => {
        const previousDay = moment(date).subtract(week ? 7 : 1, 'day');
        setDate(previousDay);
      };
    
      const handleNextDay = () => {
        const nextDay = moment(date).add(week ? 7 : 1, 'day');
        setDate(nextDay);
      };  

    
      const groupedData = _.groupBy(_.flatten(Object.values(items)), 'name');
      const itemsWeek = _.map(groupedData, (items, name) => ({
          name,
          time: _.sumBy(items, item => Number(item.time))
        }));
      itemsWeek.sort((a,b) => b.time - a.time);   

    const chart = itemsWeek || [];    
    const totalTime = chart.reduce((total, item) => (total + parseInt(item.time)), 0);  
    const weekStart = moment(date, 'YYMMDD').startOf('isoWeek');
    const weekEnd = moment(date, 'YYMMDD').endOf('isoWeek');  
    const formatedDate = !week ? moment(date, 'YYMMDD').format('MMM DD, YYYY') : `${weekStart.format('MMM DD, YYYY')} - ${weekEnd.format('MMM DD, YYYY')}`;
    const formatedLastUpdate = moment(lastUpdate).format('LTS');   
    
    return (                            
        <div style={{padding: 10}}>	
            {!isToday&&<Message style={{marginBottom:30}} warning header="Today" content="No activity today"/>}		
            <h2 style={{marginBottom : 0, marginLeft: 20}}>
                <span className='date-box' style={{width: '100%', textAlign : 'right'}}>                
                    <span style={{fontSize: '0.9em'}}>{formatedDate}</span>
                    <Icon className='date-change' style={{marginLeft: 10}} name='angle left' onClick={handlePreviousDay} />                   
                    <Icon className='date-change' name='angle right' onClick={handleNextDay} />        
                    <DatePicker_2023 date={moment(date)} includes={dates} onChangeDate={handleChangeDate}/>   
                    <span className={`tab-button day ${!week ? 'active' : ''}`} onClick={() => setWeek(false)}>DAY</span>            
                    <span className={`tab-button week ${week ? 'active' : ''}`} onClick={() => setWeek(true)}>WEEK</span>                                                                                               
                    <Icon title="Refresh" name='refresh' className='date-change' style={{fontSize : '0.8em', marginLeft : 20}} onClick={() => setLastUpdate(moment())}/>  
                    <span style={{fontSize: '0.9em'}}>{formatedLastUpdate}</span>                      
                </span>           
            </h2>	                                 
            <AppChartTable chart={chart} totalTime={totalTime}/>
        </div>                        
    )
}

const mapStateToProps = (state) => ({
    week: state.user.week,
    date: state.user.date
  });
  
  const mapDispatchToProps = {
    setWeek,
    setDate
  };

  export default connect(mapStateToProps, mapDispatchToProps)(withUID(AppChart));
