
export function lastSeen(unixTime) {
    if (!unixTime) return 'last seen a long time ago';
    const ONLINE_TIMEOUT = 15;

    const lastTime = unixTime * 1000;
    const seconds = Math.floor((new Date() - lastTime) / 1000);  

    if (seconds <= ONLINE_TIMEOUT) return 'online';
    const minutes  = Math.floor(seconds / 60);    
    if (minutes < 1) return 'last seen just now';        
    if (minutes < 2) return 'last seen 1 minute ago'
    if (minutes < 60) return `last seen ${minutes} minutes ago`

    const d = new Date(lastTime);
    const hh = d.getHours();
    const m = d.getMinutes();
    const mm = m < 10 ? '0' + m : m;
    const time = hh + ':' + mm;

    const today = new Date().setHours(0, 0, 0);    
    if (lastTime >= today) return `last seen today at ${time}`;

    const D = d.getDate();    
    const DD = D < 10 ? '0' + D : D;    
    const M = d.getMonth() + 1;
    const MM = M < 10 ? '0' + M : M;    
    const YYYY = d.getFullYear() % 100;

    const date = DD + '.' + MM + '.' + YYYY;

    return `last seen ${date} at ${time}`;
  }

  export function getOnlineStatus(unixTime, status) {

    const ONLINE_TIMEOUT = 20;
    const statusText = status === '0' ? 'active' : 'paused';

    const lastTime = unixTime * 1000;
    const seconds = Math.floor((new Date() - lastTime) / 1000);  

    return seconds <= ONLINE_TIMEOUT ? statusText : 'offline';    
  }

  export const secondsToString = (seconds) => {
    const ss = seconds % 60;
    const mm = Math.floor(seconds % 3600 / 60);
    const hh = Math.floor(seconds / 3600);
    
    if (hh > 0) {return mm > 0 ? `${hh} h ${mm} m` : `${hh} h` }
    if (mm > 0) {return `${mm} m` }
    if (ss > 0) {return `${ss} s`}
    return '-';
  }
  
  export function minutesToString (minutes) {
    const mm = minutes % 60;
    const hh = Math.floor(minutes / 60);
  
    if (hh === 0) { return `${mm} minutes` }
    if (mm === 0) {return hh === 1 ? `1 hour` : `${hh} hours` }    
    return hh === 1 ? `1 hour ${mm} minutes` : `${hh} hours ${mm} minutes`  
  }
  
  export function percent (x, y) {
    if (x*y===0) return '';
    return Number.parseFloat(x/y * 100).toFixed(1) + '%';
  }

  export function extractHostname(url) {
    var hostname;
    // find & remove protocol (http, ftp, etc.) and get hostname
    if (url.indexOf("//") > -1) {
      hostname = url.split('/')[2];
    } else {
      hostname = url.split('/')[0];
    }
    // remove subdomain (www)
    hostname = hostname.replace(/^www\./, '');
    // find & remove port number
    hostname = hostname.split(':')[0];
    // find & remove "?"
    hostname = hostname.split('?')[0];
    return hostname;
  }

export function versionToNumber(ver) {
  const version = ver.split('.');
  return version[0]*100 + version[1]*10 + version[2];
}

