import React, { Component } from 'react';
import {Search, Button, Dropdown, List} from 'semantic-ui-react';
import {statusOptions} from '../consts';
import WebIcon from './../UI/WebIcon';
import domains from './domains';
import _ from 'lodash';

const resultRenderer = ({ title }) => <WebIcon name={title}>{title}</WebIcon>
const source = domains.map(item => {return ({title : item})})

export default class WebsiteListWithSearch extends Component {
    state = {
        isLoading: false,        
        value: '',
        added : '',
        source : source,
        results: source.slice(0,3)        
    }

    handleResultSelect = (e, { result }) => this.setState({ value: result.title })

    handleSearchChange = (e, { value }) => {
      this.setState({ isLoading: true, value })
      setTimeout(() => {
        if (this.state.value.length < 1) return this.setState(
            {
                value : '',
                isLoading: false, 
                results: this.state.source.slice(0, 3),        
            }
        )
  
        const re = new RegExp('^' + _.escapeRegExp(this.state.value), 'i')        
        const isMatch = (result) => re.test(result.title)
  
        this.setState({
          isLoading: false,
          results: _.filter(this.state.source, isMatch).slice(0,3),
        })
      }, 300)
    }
  
    handleAddItem = () => {       
        const value = this.state.value; 
        if (value !== '') {
            const list = Object.assign([], this.props.list);
            const r = list.find(item => item.name === value);
            if (!r) {                
                list.push({name : value, status: 'limit'});                                
                this.props.onChange(this.props.name, list);                
            }
            this.setState({added: value, value : ''});            
        }
    }

    handleKeyPress = (event) => {
        if (event.key === 'Enter') this.handleAddItem();
    }    

    handleRemoveItem = (removedItem) => {                
        const list = [...this.props.list];        
        const index = list.findIndex(item => item.name === removedItem);
        if (index !== -1) {
            list.splice(index, 1);            
            this.props.onChange(this.props.name, list);
        }
    }    

    handleChangeStatus = (name, value) => {
        const newItem = {name, status : value};
        const list = [...this.props.list].map(item => item.name === name ? newItem : item);
        this.props.onChange(this.props.name, list);
    }

    componentDidUpdate(prevProps) {    
        if (this.props.list.length !== prevProps.list.length) {
          const s = domains.slice();
          this.props.list.forEach(item => {              
              const index = s.indexOf(item.name); 
              if (index > -1) s.splice(index, 1);
          });
        
          const source = s.map(item => {return ({title : item})});          
          const results = source.slice(0, 3);
          this.setState({source, results});
        }
      }

    render() {			
        const {list = []} = this.props;  
        const { isLoading, value, results, added} = this.state  
        const listItems = list.map((item) => {                  
            const highlight = item.name === added ? 'highlight' : '';       
            return (                
                <List.Item key={item.name}>                               
                    <div className={`blacklist-container ${highlight}`}>
                        <div className='blacklist-title'>
                            <WebIcon name={item.name}><span title={item.name} style={{color : 'rgba(0,0,0,.87)'}} >{item.name}</span></WebIcon>
                        </div>  
                        <div className='blacklist-status'>
                            <Dropdown                                                     
                                value = {item.status}
                                size="large"
                                onChange = {(e, {value}) => this.handleChangeStatus(item.name, value)}
                                options={statusOptions()}
                                searchInput={{ autoFocus: true }}
                            />
                        </div>
                        <div className='blacklist-remove'>
                            <Button size='mini' icon='delete' onClick={() => this.handleRemoveItem(item.name)}/>                                   
                        </div>                                                                                        
                    </div>                                                          
            </List.Item>              
            )                        
        });     
           
        return (                        
            <>
                <div style={this.props.style} >
                    <List verticalAlign='middle' selection size="large">{listItems}</List>                               
                </div>                        
                <div style={{marginTop : "10px", display : 'flex', flexDirection : 'row', marginRight : '10px'}}>
                        <Search
                            loading={isLoading}
                            onResultSelect={this.handleResultSelect}                        
                            onSearchChange={_.debounce(this.handleSearchChange, 500, {
                            leading: true,
                            })}
                            onFocus={()=> this.setState({added : ''})}
                            fluid
                            results={results}
                            value={value}
                            {...this.props}                    
                            minCharacters={0}
                            showNoResults={false}
                            icon={''}
                            input={{placeholder: "Enter a website address (i.e. facebook.com)", icon: false, fluid : true}}
                            style={{flexGrow: 1, marginRight : '10px'}}
                            className="search-websites"
                            resultRenderer={resultRenderer}
                        />  
                        <Button onClick={this.handleAddItem}>Add</Button>
                </div>
            </>                
        )
    }
}  