import React, { Component } from 'react'
import firebase from '../../firebase';
import ReportTable from '../UI/ReportTable';
import {Table, Dropdown, Icon} from 'semantic-ui-react';
import moment from 'moment';
import AppIcon from '../UI/AppIcon';
import withUID from './../withUID';
import ToolTip from '../UI/ToolTip';
import withAppFilter from './withAppFilter';

const ApplicationItem = ({item, onAddClick}) => {
    const {name, title, time} = item;         
	return (
        <>        
        <Table.Cell title={name}><AppIcon key = {name} name={name}/></Table.Cell>
        <Table.Cell title={title} className="trunc">{title}</Table.Cell>          
        <Table.Cell>{moment.utc(time, 'X').format("D MMM, h:mm a")}</Table.Cell>    
        <Table.Cell>
            <Dropdown className="actions-dropdown" icon="ellipsis vertical" direction="left" text=''>       
                <Dropdown.Menu>
                    <Dropdown.Item onClick={() => onAddClick(name, 'block')}>
                        <div><Icon className="block-icon" name="minus circle"/>Block app<ToolTip text='Prevent access to this app.'/></div>
                    </Dropdown.Item>
                    <Dropdown.Item  onClick={() => onAddClick(name, 'limit')}>
                        <div><Icon className="limit-icon" name="time"/>Limit app<ToolTip text='Enforce time restrictions for this app, guided by your settings from the App Limits tab'/></div>
                    </Dropdown.Item>                    
                </Dropdown.Menu>
            </Dropdown>
        </Table.Cell>               
        </>
	)
}

class LastApps extends Component {
    state = {
        alerts : [], // the current report       
        start : moment(), // the date of the current report
        last : undefined, // the date of the last report      
        reportRef : null, // ref to firebase {applications}                
    }

    getAlerts = (reportRef) => {       
        if (reportRef) {        
            reportRef.orderByChild('time').limitToLast(60).on('value',
            // success
            (snapshot) => {                
                const alerts = Object.values(snapshot.val() || {});  
                alerts.sort((a, b) => {
                    return b.time - a.time
                });
                this.setState({alerts})            
            },
            // error
            (error) => {
                console.log(error);            
            });
        }     
    }
    
    componentDidMount() {
        const {currentComputer, uid} = this.props;
        if (!currentComputer) return;        
        const reportRef = firebase.database().ref(`${uid}/${currentComputer}/reports/lastApps`);                    
        this.setState({reportRef});               
        this.getAlerts(reportRef);                         
    }     

    componentWillUnmount() {
        if (this.state.reportRef) this.state.reportRef.off();    
    }     

    render() {			
        const chart = this.state.alerts || [];        
		const collumns = [			
            { title : 'Name', style : {width : ""}},
            { title : 'Title', style : {width : ""}},              
			{ title : 'Time', style : {width : "180px"}},
            { title : ' ', className : 'th-fixed-50'},              			
        ];                
        return (
            <>                             
    		    <div style={{padding: 10}}>	                    				    
            	    <ReportTable unstackable collumns={collumns} list={chart}>
            		    { (index, item) => <ApplicationItem index={index} item={item} onAddClick={this.props.onAddApp} />}
            	    </ReportTable>
        	    </div>                
            </>
        )
    }
}

export default withUID(withAppFilter(LastApps));
