import React, {useState, useEffect} from "react";
import firebase from '../../firebase';
import withUID from '../withUID';
import moment from 'moment';
import AppIcon from '../UI/AppIcon';
import WebIcon from '../UI/WebIcon';

const LastActivity = ({uid, currentComputer}) => {
    const [lastActivity, setLastActivity] = useState({});

    useEffect(() => {
        const ref = firebase.database().ref(`${uid}/${currentComputer}/reports/last`);
             ref.on('value', (snapshot) => {
                 const raw = snapshot.val() || {};                              
                 setLastActivity(raw);
             },    
             (error) => {
                 console.log(error);            
             });
 
             return () => ref.off();
     }, [uid, currentComputer]);   
     
     const {a, w, u} = lastActivity;
     const application = a;
     const website = w;
     const time = u;

    const link = /^(f|ht)tps?:\/\//i.test(website) ? website : 'http://' + website;  
    const lastTime = time ? moment.utc(time, 'X').format("D MMM, h:mm:ss a") : '';     

    if (!application) return null;

    return (  
        <div>
            <h3>Latest Activity
                <div className="app-link">{lastTime}</div>                                        
            </h3>            
            <div className="app-chart" style={{marginTop: 25}}>        
                <div style={{marginTop : 10, display: 'flex'}}>Application: <AppIcon key={application} name={application}/></div>
                {website&&<div  style={{marginTop : 10, display: 'flex'}}>Website: <WebIcon key={website} name={website}>{<a href={link} rel="noopener noreferrer" target="_blank">{website}</a>}</WebIcon></div>}
            </div>
        </div>        
    )

}

export default withUID(LastActivity);
